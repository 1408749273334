<!-- 有效期预警 -->

<template>
  <div class="validityWarning">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="nameValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="supplierName" label="供应商名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="品类"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="supplyPrice" label="供货价"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="inventoryCount" label="库存数量"></el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="deliveryNum" label="发货单号"></el-table-column> -->
          <!-- <el-table-column show-overflow-tooltip prop="tendersLabel" label="标号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="productDate" label="生产日期"></el-table-column>
          <el-table-column min-width="120" prop="expirationDay" show-overflow-tooltip label="保质期(天)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="expirationDate"
            label="剩余保质期(天)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="warningType" label="预警类型">
            <template slot-scope="scope">
              {{ getWarningTypeName(scope.row.warningType) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" fixed="right" min-width="100" align="center">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCancel(scope.row)">取消预警</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      nameValue: '', // 搜索商品名称
      barCode: '', // 搜索商品条形码
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getShopProductWarnList();
  },
  methods: {
    // 获取商品列表
    getShopProductWarnList() {
      this.loading = true;
      this.$axios.get(this.$api.getShopProductWarnList, {
        params: {
          productName: this.nameValue || null, // 商品名称
          barCode: this.barCode || null, // 商品名称
          shopId: this.$store.state.userInfo.shopId,//商超id
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code === 100) {
          this.tableData = res.data.result.list.map(item => ({
            ...item,
            expirationDate: item.expirationDate < 0 ? "0" : item.expirationDate
          }));
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getWarningTypeName(warningType) {
      if (warningType == 1) {
        return '第一次预警';
      } else if (warningType == 2) {
        return '第二次预警';
      } else {
        // 可以根据需要处理其他情况，比如未知的预警类型
        return ' ';
      }
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getShopProductWarnList();
    },
    // 取消预警
    handleCancel(row) {
      this.$confirm('确定取消预警吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$api.sendUpdateProductWarn, {
          shopInventoryInfoId: row.shopInventoryInfoId,
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message({
              type: 'success',
              message: '取消预警成功！'
            });
            this.getShopProductWarnList();
            // setTimeout(() => {
            //   location.reload()
            // }, 1500);
          }
        });
      })
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getShopProductWarnList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getShopProductWarnList();
    },
    // 颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 11 && row.expirationDate == '0') {
        return { color: '#E35B5A' };
      }
    }

  }

}



</script>

<style scoped lang="scss">
.validityWarning {


  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 180px;
      margin-left: 20px;
    }
  }
}

/* input number类型取消增减箭头*/
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// 光标上移
/deep/.el-input__inner {
  line-height: 1px !important;
}
</style>
